<template>
  <div class="component-uploadfile">
    <div class="title">
      {{ title }}
    </div>
    <label
      :class="[selectedFileClass, 'button-content']"
      :for="forId"
    >
      <img
        src="@/assets/icons/ic_upload.svg"
        class="icon"
      >
      <div
        class="text-upload ml-2"
      >
        <div class="title-text-upload">
          {{ hasSelectedFileText }}
        </div>
        <div class="mt-2 subtitle-text-upload">
          {{ text }}
        </div>
      </div>
    </label>
  </div>
</template>
<script>
export default ({
  name: 'ButtonFile',
  props: {
    forId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    item: {
      type: File,
      default: () => null,
    },
  },
  computed: {
    selectedFileClass() {
      if (this.item !== null) {
        return 'file-selected';
      }
      return '';
    },
    hasSelectedFileText() {
      if (this.item !== null) {
        return this.$t('components.form_documents.title_button_document_selected');
      }
      return this.$t('components.form_documents.title_button_document');
    },
  },
});
</script>

<style lang="scss" scoped>
  .component-uploadfile {
    width: 100%;
    margin-bottom: 52px;
    .title {
      font-size: 1.1em;
      font-weight: 600;
      color: $color_label_document;
    }
    .button-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      background: $background_input_document;
      box-shadow: 0px 4px 14px $box_shadow_input_document;
      border-radius: 10px;
      padding: 24px;
      margin-top: 16px;
      .icon {
        opacity: 0.6;
        width: 50px;
      }
      .text-upload {
        color: $step_disabled;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .title-text-upload {
          font-size: 1.1em;
        }
        .subtitle-text-upload {
          font-size: 1em;
        }
      }
      &.file-selected {
        .text-upload {
          color: $color_dark;
          .subtitle-text-upload {
            color: $color_primary;
          }
        }
        .icon {
          opacity: 1;
        }
      }
    }
  }
</style>
