<template>
  <button
    class="rectoplus-button"
    :disabled="disabled"
    @click="onClick"
  >
    {{ text }}
  </button>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss">
  .rectoplus-button {
    color: $color_primary;
    background: transparent;
    font-weight: bold;
    border: 2px solid $color_primary;
    border-radius: 10px;
    font-size: 1.125em;
    transition: all 300ms ease;
  }
  .rectoplus-button:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .rectoplus-button:hover {
    background: $color_primary;
    color: $color_white;
  }
</style>
