<template>
  <div class="document-form">
    <ValidationObserver
      v-slot="{ invalid }"
    >
      <div class="check-license">
        <label class="container-checkbox">
          <input
            v-model="documents.has_environment_exemption"
            type="checkbox"
            checked="checked"
          >
          <div class="checkmark" />
        </label>

        <label class="label-checkbox">
          {{ $t('components.form_documents.labels.checkbox') }}
        </label>
      </div>
      <div class="documents">
        <div
          v-if="!documents.has_environment_exemption"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.license')"
          >
            <v-file-input
              id="license"
              v-model="documents.environment_license"
              :accept="typeSecondary"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="license-btn"
            for-id="license"
            :title="$t('components.form_documents.labels.license')"
            :text="selectedFilePdf(documents.environment_license, typeSecondary)"
            :item="documents.environment_license"
          />
        </div>
        <div
          v-else
        >
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.exemption')"
          >
            <v-file-input
              id="exemption"
              v-model="documents.exemption_environmental_license"
              :accept="typeSecondary"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="exemption-btn"
            for-id="exemption"
            :title="$t('components.form_documents.labels.exemption')"
            :text="selectedFilePdf(documents.exemption_environmental_license, typeSecondary)"
            :item="documents.exemption_environmental_license"
          />
        </div>
        <div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.social_contract')"
          >
            <v-file-input
              id="social_contract"
              v-model="documents.social_contract"
              :accept="typeSecondary"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="social-contract-btn"
            for-id="social_contract"
            :title="$t('components.form_documents.labels.social_contract')"
            :text="selectedFilePdf(documents.social_contract, typeSecondary)"
            :item="documents.social_contract"
          />
        </div>
        <div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.social_contract')"
          >
            <v-file-input
              id="business_license"
              v-model="documents.business_license"
              :accept="typeSecondary"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="business-license-btn"
            for-id="business_license"
            :title="$t('components.form_documents.labels.business_license')"
            :text="selectedFilePdf(documents.business_license, typeSecondary)"
            :item="documents.business_license"
          />
        </div>
        <div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.photographic_register')"
          >
            <v-file-input
              id="photographic_register"
              v-model="documents.photograph_register"
              :accept="typeSecondary"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="photographic-btn"
            for-id="photographic_register"
            :title="$t('components.form_documents.labels.photographic_register')"
            :text="selectedFilePhoto(documents.photograph_register)"
            :item="documents.photograph_register"
          />
        </div>

        <div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.operation_photograph_register')"
          >
            <v-file-input
              id="operation_photograph_register"
              v-model="documents.operation_photograph_register"
              :accept="typeSecondary"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="photographic-btn"
            for-id="operation_photograph_register"
            :title="$t('components.form_documents.labels.operation_photograph_register')"
            :text="selectedOperationFilePhoto(documents.operation_photograph_register)"
            :item="documents.operation_photograph_register"
          />
        </div>

        <div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.balance_calibration_certificate')"
          >
            <v-file-input
              id="balance_calibration_certificate"
              v-model="documents.balance_calibration_certificate"
              :accept="allTypeFiles"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="balance-calibration-certificate-btn"
            for-id="balance_calibration_certificate"
            :title="$t('components.form_documents.labels.balance_calibration_certificate')"
            :text="selectedFilePdf(documents.balance_calibration_certificate, allTypeFiles)"
            :item="documents.balance_calibration_certificate"
          />
        </div>

        <div>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.form_documents.labels.balance_calibration_certificate')"
          >
            <v-file-input
              id="fire_department_license"
              v-model="documents.fire_department_license"
              :accept="allTypeFiles"
              hide-input
              prepend-icon=""
              :error-messages="errors"
            />
          </ValidationProvider>
          <button-file
            id="fire-department-license-btn"
            for-id="fire_department_license"
            :title="$t('components.form_documents.labels.fire_department_license')"
            :text="selectedFilePdf(documents.fire_department_license, allTypeFiles)"
            :item="documents.fire_department_license"
          />
        </div>
      </div>
      <div class="button-next">
        <rectoplus-button
          v-if="newBusiness"
          id="nextStep3"
          :disabled="invalid"
          class="mb-5"
          :text="$t('components.register.business.conclusion.button')"
          @click="sendDocuments"
        />
        <rectoplus-button
          v-else
          :disabled="!isToEdit"
          class="mb-5"
          :text="$t('components.form_documents.send')"
          @click="sendDocuments"
        />
        <rectoplus-button-outlined
          v-if="!newBusiness"
          class="mb-5"
          :text="$t('components.form_documents.button_edit_operational_capacity')"
          @click="editOperationalCapacity"
        />
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import RectoplusButtonOutlined from '@/components/buttons/RectoplusButtonOutlined.vue';
import ButtonFile from '@/components/buttons/business/ButtonFile.vue';

export default {
  name: 'DocumentForm',
  components: {
    RectoplusButton,
    RectoplusButtonOutlined,
    ButtonFile,
  },
  props: {
    newBusiness: {
      type: Boolean,
      default: true,
    },
    isToEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: {
        has_environment_exemption: false,
        exemption_environmental_license: null,
        environment_license: null,
        social_contract: null,
        business_license: null,
        photograph_register: null,
        balance_calibration_certificate: null,
        operation_photograph_register: null,
        fire_department_license: null,
      },

      allTypeFiles: '.pdf, image/*',
      typeSecondary: '.pdf, image/*',
    };
  },
  computed: {
    ...mapGetters([
      'getBusiness',
      'hasOperationalCapacity',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchOperationalCapacity',
    ]),
    selectedFilePdf(value, type) {
      if (value === null) return this.$t('components.form_documents.subtitle_button_document', { type });
      return value.name;
    },
    selectedFilePhoto(value) {
      if (value === null) return this.$t('components.xml_uploader.label.photo');
      return value.name;
    },
    selectedOperationFilePhoto(value) {
      if (value === null) return this.$t('components.xml_uploader.label.operation_photo');
      return value.name;
    },
    sendDocuments() {
      const formData = new FormData();
      Object.keys(this.documents).forEach((document) => {
        if (this.documents[document] !== null) {
          formData.append(document, this.documents[document]);
        }
      });
      const { id } = this.getBusiness;

      this.$emit('submited', { formData, id });
    },
    editOperationalCapacity() {
      if (this.hasOperationalCapacity) {
        this.fetchOperationalCapacity({ business_id: this.getBusiness.id });
      }
      this.$router.push({ name: 'business' });
    },
  },
};
</script>
<style lang="scss" scoped>
.document-form {
  .label-checkbox {
    color: $title_color_primary;
    font-size: 1.1em;
    margin-left: 14px;
  }
  .container-checkbox {
    cursor: pointer;
    position: relative;
  }
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-color: $border_color_checkbox;
    border-width: 2px;
    border-radius: 2px;
    border-style: solid;
  }

  .container-checkbox input:checked ~ .checkmark {
    background-color: $color_primary;
    border-color: $color_primary;
  }

  .checkmark:after {
    content: "";
    position: absolute;
  }

  .container-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .container-checkbox input:checked ~ .checkmark:after {
    border: solid $color_light;
    border-width: 0 2px 2px 0;
  }

  .button-next {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .rectoplus-button {
      width: 100%;
      height: 70px;
    }
  }
  .check-license {
    display: flex;
    margin-bottom: 36px;
    align-items: center;
  }
  .documents {
    display: flex;
    flex-direction: column;
    .v-text-field {
      padding-top: 0;
      margin-top: 0;
    }
    #license-btn, #exemption_environmental_license {
      margin-bottom: 52px;
    }
  }
}
</style>
